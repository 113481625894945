import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout/layout"
import "./contacto.scss"
import ContactForm from "../components/contact-form/contact-form"
import ContactFormJotForm from "../components/contact-form/contact-form-jotform"

export default function Contacto() {

  return (
    <React.Fragment>
      <SEO title="Formulario Contacto" />
      <Layout pageId="contact">
        <div className="contact-container">
          {/* <ContactForm /> */}
          <ContactFormJotForm />
        </div>
      </Layout>
    </React.Fragment>
  )
}
